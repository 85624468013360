import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../../../components/layout'
import ReactMarkdown from "react-markdown"
import { Helmet } from "react-helmet"

const BlogTemplate = ({ data }) => (
  <Layout sitePage="site-page" pageInfo={{ pageName: "Blog Detail" }}>
    <Helmet defer={false}>
        <title>{data.strapiBlog.title}</title>
        <meta name="description" content={data.strapiBlog.title} />
    </Helmet>
    <h1 className="title-detail-blog">{data.strapiBlog.title}</h1>
    <div className="wrapper-detail-blog">
      <p className="author-date">Posted: <span className="date-time">{data.strapiBlog.updated_at}</span></p>
      <p className="author-date">Writen by: <span className="date-time">{data.strapiBlog.author.username}</span></p>
      <Img fixed={data.strapiBlog.image.childImageSharp.fixed} alt="900x500" className="image-detail-blog lazyload" />
      <ReactMarkdown className="content-detail-blog" source={data.strapiBlog.content} />
    </div>
  </Layout>
)

export default BlogTemplate

export const query = graphql`
  query BlogTemplate($url: String) {
    strapiBlog(url: {eq: $url}) {
      title
      content
      image {
          childImageSharp {
            fixed(width: 2000) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      updated_at(formatString: "MMMM Do YYYY")
      author {
        id
        username
      }
    }
  }
`
